import styled from '@emotion/styled'
import { useRouter } from 'next/router'

import onClickEvents from '@helpers/home/on-click-callbacks'
import getPath from '@helpers/path'
import ROUTES from '@helpers/routes'
import Colors from 'microcomponents/colors'

import { TEST_IDS } from './test/constants'

import { arrayOf, bool, func, InferProps, number, oneOfType, shape, string } from 'prop-types'

export default function ThreeColumnLayout(props: InferProps<typeof ThreeColumnLayout.propTypes>) {
  const router = useRouter()

  if (props.columns) {
    const bannerName = props.banner_name
    const redirectLocation = props.call_to_action ? props.call_to_action.url : ROUTES.MENU
    const { href: redirectWithQueryParams } = getPath(
      { pathname: redirectLocation, query: router.query },
      { removeParams: ['slug'] }
    )

    return (
      <Row data-e2eid={TEST_IDS.THREE_COLUMN_LAYOUT}>
        {Array.isArray(props.columns) &&
          props.columns.map((column, i) => {
            return (
              <Column key={i}>
                <Icon
                  src={column.column_image.url}
                  alt={column.column_image.alt}
                  srcSet={`${column.column_image.sizes.small_square} ${column.column_image.sizes['small_square-width']}w, ${column.column_image.sizes.mobileImage} ${column.column_image.sizes['mobileImage-width']}w`}
                  sizes={`(max-width: 767px) ${column.column_image.sizes['small_square-width']}w, ${column.column_image.sizes['mobileImage-width']}w`}
                />
                <Title>{column.column_title}</Title>
                <Description>{column.column_text}</Description>
              </Column>
            )
          })}
        <ShopNow
          data-e2eid={TEST_IDS.SHOP_NOW}
          onClick={() => onClickEvents(bannerName, redirectWithQueryParams, props.toggleAddress, 'shop_now')}>
          {props.call_to_action?.title || 'SHOP NOW'}
        </ShopNow>
      </Row>
    )
  } else {
    return null
  }
}

ThreeColumnLayout.propTypes = {
  columns: oneOfType([
    bool,
    arrayOf(
      shape({
        column_image: shape({
          url: string,
          alt: string,
          sizes: shape({
            small_square: string,
            'small_square-width': number,
            mobileImage: string,
            'mobileImage-width': number
          })
        }),
        column_title: string,
        column_text: string
      })
    )
  ]).isRequired,
  toggleAddress: func.isRequired,
  call_to_action: shape({
    title: string,
    url: string
  }),
  banner_name: string.isRequired // For Segment tracking: This field will be used to track the name of the 3 column layout that was used when the Banner.Click event is triggered
}

const colorMap = {
  black: Colors.homepageRedesign[0],
  yellow: Colors.homepageRedesign[1],
  white: Colors.homepageRedesign[2]
}

const Title = styled.h3`
  font-family: 'Suisse Intl Mono Bold', monospace;
  font-size: 24px;
`

const Description = styled.p`
  display: block;
  font-family: 'Suisse Intl Mono Regular', monospace;
  font-size: 14px;
`

const Icon = styled.img`
  display: block;
`

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  justify-content: center;

  a {
    color: ${colorMap.black};
  }

  // Desktop view
  @media (min-width: 767px) {
    max-width: 1200px;
    margin: 60px auto;
  }

  // Mobile View
  @media (max-width: 767px) {
    background-color: ${colorMap.yellow};
    height: 1122px;

    a {
      color: ${colorMap.white};
    }
  }
`

const Column = styled.div`
  text-align: center;
  justify-content: center;

  // Desktop view
  @media (min-width: 767px) {
    padding: 40px 30px;
    max-width: 300px;
    margin: 0 25px;

    img {
      width: 75px;
      height: 72px;
      margin-left: auto;
      margin-right: auto;
    }

    h3 {
      height: 62px;
      margin: 30px 20px;
    }

    p {
      margin-left: auto;
      margin-right: auto;
      width: 230px;
      height: 72px;
    }
  }

  // Mobile view
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    margin-top: 75px;

    img {
      width: 75px;
      height: 72px;
      margin: 0 auto;
    }

    p {
      max-width: 250px;
      max-height: 80px;
      margin: 0 auto;
    }

    h3 {
      max-width: 300px;
      margin: 30px auto;
    }
  }
`

export const ShopNow = styled.a`
  font-family: Gilroy;
  font-style: italic;
  line-height: 1.24;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.27);
  padding: 10px;
  flex-basis: 100%;
  font-size: 17px;
  min-width: 129px;
  height: 40px;

  // Desktop view
  @media (min-width: 767px) {
    margin: 70px 708px;
    margin-top: 20px;
    background-color: ${colorMap.yellow};
  }

  // Mobile view
  @media (max-width: 767px) {
    background-color: ${colorMap.black};
    margin: 80px 452px;
  }
`
